document.addEventListener('DOMContentLoaded', function () {
  var hamburgerLines = document.querySelector('.hamburger-lines');
  var mobileMenu = document.querySelector('.mobileMenu');

  hamburgerLines.addEventListener('click', function () {
    // Toggle active class on hamburger-lines
    hamburgerLines.classList.toggle('active');

    // Toggle visibility of mobileMenu
    if (mobileMenu.style.display === 'block') {
      mobileMenu.style.display = 'none';
    } else {
      mobileMenu.style.display = 'block';
    }
  });

  var accordionHeaders = document.querySelectorAll('.accordionHeader');

  accordionHeaders.forEach(function (header) {
    header.addEventListener('click', function () {
      var content = header.closest('.accordion').querySelector('.accordionContent');


      if (content.style.display === 'block') {
        content.style.height = '0';
        content.style.display = 'none';
      } else {
        content.style.display = 'block';
        content.style.height = content.scrollHeight + 'px';
      }
    });
  });

});